import React from "react";

class ExtendImage extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            on: this.props.on,
            image: this.props.image
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ on: nextProps.on, image: nextProps.image });
    }


    render() {
        if(this.state.on == true){
        return (
           <>
           <div class="h-screen w-full bg-black opacity-75 -mb-full z-20 fixed font-light"></div>
            <div class="flex flex-col items-center justify-center h-screen w-full z-50 fixed" onClick={()=> this.setState({on: false, image: null})}>
                <img src={this.state.image} class="max-h-3/4"/>
                <div class="text-white relative">Some Text dont know</div>
            </div>
           </>
        );
        }else{
            return(<></>);
        }
    }
}

export default ExtendImage;