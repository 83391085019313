import { render } from "@testing-library/react";
import React from "react";

import ExtendImage from '../utility/imageViewer';

// class GetImages extends React.Component {
//   constructor(props) {
//     super(props);
//     this.props = props;

//   }


//   render() {

//         }
// }


class Galery extends React.Component {
    constructor() {
        super();
        this.state = {
            on: false,
            image: null,
            page: 0,
            display: false,
        }
    }

    GetImages(page) {
        if (page == 0) return (<>
            <img src="https://media.discordapp.net/attachments/788440620288442388/912479866652344360/IMG_0354.jpg" class="pr-3 cursor-pointer max-h-480 " alt="image_1" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            <img src="https://media.discordapp.net/attachments/788440620288442388/912792244887650374/IMG_0490.jpg" class="pr-3 cursor-pointer max-h-480 pt-3" alt="image_4" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            <img src="https://media.discordapp.net/attachments/788440620288442388/912479867222765588/IMG_0353.jpg" class="pr-3 cursor-pointer max-h-480" alt="image_2" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            <img src="https://media.discordapp.net/attachments/788440620288442388/912792245684564038/IMG_0489.jpg" class="pr-3 cursor-pointer max-h-480 mt-3" alt="image_3" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            <img src="https://media.discordapp.net/attachments/788440620288442388/912479866006429716/IMG_0357.jpg" class="pr-3 cursor-pointer max-h-480 pt-3" alt="image_5" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            <img src="https://cdn.discordapp.com/avatars/208343813855510530/81d122aa28919d0f1068bb64cc1d0f33.webp?size=512" class="pt-3 pr-3 cursor-pointer max-h-480" alt="image_6" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
        </>)
        else if (page == 1)
            //this is the House section
            return (<><img src="https://media.discordapp.net/attachments/788440620288442388/912479866652344360/IMG_0354.jpg" class="pr-3 cursor-pointer max-h-480 " alt="image_1" onClick={(e) => this.setState({ image: e.target.src, on: true })} /></>)
        else if (page == 2)
            //this is the landscape section
            return (<>
                <img src="https://media.discordapp.net/attachments/788440620288442388/912792244887650374/IMG_0490.jpg" class="pr-3 cursor-pointer max-h-480 pt-3" alt="image_4" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
                <img src="https://media.discordapp.net/attachments/788440620288442388/912479867222765588/IMG_0353.jpg" class="pr-3 cursor-pointer max-h-480 pt-3" alt="image_2" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
                <img src="https://media.discordapp.net/attachments/788440620288442388/912792245684564038/IMG_0489.jpg" class="pr-3 cursor-pointer max-h-480 pt-3 pb-10" alt="image_3" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            </>)
        else if (page == 3)
            //this is the portrait section
            return (<>
                <img src="https://media.discordapp.net/attachments/788440620288442388/912479866006429716/IMG_0357.jpg" class="pr-3 cursor-pointer max-h-480 pt-3" alt="image_5" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
                <img src="https://cdn.discordapp.com/avatars/208343813855510530/81d122aa28919d0f1068bb64cc1d0f33.webp?size=512" class="pt-3 pr-3 pb-10 cursor-pointer max-h-480" alt="image_6" onClick={(e) => this.setState({ image: e.target.src, on: true })} />
            </>)
        else return (<>OH NO, ITS BROKEN</>)
    }
    render() {
        return (
            <>
                <ExtendImage image={this.state.image} on={this.state.on} />
                <div class="pl-5 min-h-calc sm:ml-32 sm:mr-32 sm:pt-36 pt-32">
                    <div class="sm:text-5xl text-4xl font-extrabold ">Gallery</div>
                    <div class="text-light mb-5">A selection of my work. For more, please select a category at the top</div>
                    <div id="image-container" class="flex flex-wrap w-full">
                        {this.GetImages(new URLSearchParams(this.props.location.search).get("page") ? new URLSearchParams(this.props.location.search).get("page") : 0)}
                    </div>
                </div>
            </>
        )
    }
}

export default Galery;