import React from "react";

import { AiOutlineHome, AiFillGithub, AiOutlineTwitter, AiFillYoutube } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs';
import {GiHamburgerMenu } from 'react-icons/gi';
import { SiPolywork } from "react-icons/si";
import { IoMdArrowDropdown } from 'react-icons/io';
import { CgMail } from "react-icons/cg";

class Header extends React.Component{
    constructor(){
        super();
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            "clicked": false
        }
    }

    handleClick(){
       if(this.state.clicked == false){
        document.getElementById("menuContainer").className = document.getElementById("menuContainer").className.replace(" -mt-56"," -mt-5")
        this.setState({
            clicked: true
        })
       }else{
        document.getElementById("menuContainer").className = document.getElementById("menuContainer").className.replace(" -mt-5"," -mt-56")
        this.setState({
            clicked: false
        })
       }
       console.log(document.getElementById("menuContainer").className)
    }
 
    render(){
        
        return(
            <>
        <div id="header" class="h-20 z-10 top-0 w-full fixed" style={{boxShadow: "0px 6px 24px 5px #000000; !important"}}>
                <div id="header-container" class="block w-full  m-auto xl:w-3/4 sm:relative absolute"  style={{zIndex:"3"}}>
                {/* <GiHamburgerMenu class="text-white float-right w-7 h-7 mr-5 mt-6 sm:hidden sm:z-0 z-50 relative" onClick={()=>this.handleClick()}/> */}
                    <a href="/">
                    </a>
                    <div id="menu" class="mt-1 bg-gray-800 sm:z-0 z-10">
                        <ul class="text-white font-bold p-5 sm:pt-5 pt-12 -mt-56 sm:mt-0 sm:float-right sm:inline text-xl duration-200 ease-in-out bg-gray-800 sm:bg-transparent" id="menuContainer">
                            <li class="sm:inline sm:float-right mt-3 sm:mt-0 mr-3 hover:text-gray-500 duration-200 ease-in-out hidden"><a href="https://twitter.com/MBrickstar"><AiOutlineTwitter  class="sm:float-left w-7 h-6 mr-1" /></a></li>
                            <li class="sm:inline sm:float-right mr-3 mt-3 sm:mt-0 hover:text-gray-500 duration-200 ease-in-out hidden"><a href="https://www.instagram.com/bendix_en/"><BsInstagram class="sm:float-left w-5 h-6 mr-1" /></a></li>
                            <li class="sm:inline sm:float-right mr-3 hover:text-gray-500 duration-200 ease-in-out"><a href="https://github.com/mrbrickstar"><AiFillGithub class="float-left w-5 h-6 mr-1" /></a></li>
                            <li class="sm:inline sm:float-right mr-3 mt-3 sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="https://www.polywork.com/bendix"><SiPolywork class="float-left w-5 h-6 mr-1 hidden sm:block"/></a></li>
                            {/* <li class="sm:inline sm:float-right mr-3 mt-3 sm:mt-0 hover:text-gray-500 duration-200 ease-in-out ">  <div class="cursor-pointer" onClick={()=> this.setState({display: !this.state.display, image: null, on: false})}>
                            Gallery <IoMdArrowDropdown class="inline mt-0 -ml-1"/></div>
                                <div class={`float-right bg-gray-100 w-min sm:-mr-32 -ml-10 mt-3 rounded-md font-bold cursor-pointer relative z-50 -mb-full ${this.state.display == true ? "block": "hidden"}`}>
                                    <a href="/gallery?page=1"><div class="font-bold p-5 hover:bg-gray-200 hover:rounded-md transform ease-in-out duration-200 text-gray-500" onClick={(()=>this.setState({page: 1, display: !this.state.display, image: null, on: false}))} >House</div></a>
                                    <a href="/gallery?page=2"><div class="font-bold p-5 hover:bg-gray-200 transform hover:rounded-md ease-in-out duration-200 text-gray-500" onClick={(()=>this.setState({page: 2, display: !this.state.display, image: null, on: false}))} >Landscape</div></a>
                                    <a href="/gallery?page=3"><div class="font-bold p-5 hover:bg-gray-200 transform  hover:rounded-md ease-in-out duration-200 text-gray-500" onClick={(()=>this.setState({page: 3, display: !this.state.display, image: null, on: false}))} >Portrait</div></a>
                                </div></li> */}
                            <li class="sm:inline sm:float-right mr-3  mt-3 sm:mt-0 hover:text-gray-500 duration-200 ease-in-out"><a href="/"><AiOutlineHome class="float-left w-5 h-6 mr-1 hidden sm:block" /> Home</a></li>
                            
                        </ul>
                    </div>
                </div>
            </div>
            <div class="h-20 top-0 w-full fixed bg-black z-0"></div>
            </>
        )
    }
}

export default Header;