import React from "react";

import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai';
import { IoMdArrowDropdown } from 'react-icons/io';

import ExtendImage from '../utility/imageViewer';

class Home extends React.Component {
    constructor() {
        super();
        this.state = {
            on: false,
            image: null,
            page: 0,
            display: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height
        document.getElementById("scroll-container").style.marginTop = "-" + (scrolled * 500) + "px";
    }

    render() {
        return (
            <>
                <ExtendImage image={this.state.image} on={this.state.on} />
                <div id="container-big-image" class="h-screen w-full bg-main bg-no-repeat bg-cover bg-center block" style={{ zIndex: "2", position: "relative" }}>
                    <div class="h-screen w-full bg-no-repeat bg-cover bg-center block opacity-80 -mb-full" style={{ background: "radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.8) 100%)" }}></div>
                    <div class="items-center relative sm:-mt-10 top-1/3 pt-20">
                        <div id="title" class="text-white font-bold ">
                            <span class="sm:text-8xl text-4xl p-2 w-full text-center block -t-100 mb-2" >Bendix Siegert</span>
                            <div class="h-1 w-1/3 bg-white rounded-full m-auto"></div>
                        </div>
                        <div id="caption" class="text-white block text-center -mt-2 sm:mt-4 m-auto sm:text-xl text-xl sm:w-1/2 w-full p-2">Full Stack Webentwickler, Reiter und Filmemacher.</div>
                    </div>
                    <a href="#about-container">
                        <AiOutlineArrowDown class="bottom-0 h-10 mb-6 animate-bounce absolute m-auto w-full text-white text-center" onClick={(e) => this.setState({ image: null, on: false })} />
                    </a>
                </div>
                <div id="about-container" class=" h-max w-full float-none bg-white z-10 relative shadow-big" >
                    <div class="w-3/3 m-auto sm:p-36 p-12 z-10" id="scroll-container">

                        <div id="image-container" class="flex flex-wrap w-fit justify-center">
                            <div class="w-full mb-4 h-1/2 sm:w-1/3 p-2">
                                <img src="/res/about-image.png" class="w-full h-full object-cover mr-6 pr-6" alt="me" />
                            </div>
                            <div id="text-container" class="text-light flex justify-center items-center">
                                <div id="cont" class="align-middle  h-auto">
                            <div class="sm:text-5xl text-4xl font-extrabold">Über mich</div>
                            <div class="text-light mb-5 max-w-3xl">Hey, mein Name ist Bendix und ich bin momentan 18 Jahre alt. Wohnen tue ich gerade im Großraum Köln, komme aber aus Leverkusen und bin deshalb auch glühender Bayer 04 Fan. In meiner Freizeit treffe ich mich gerne mit Freunden, programmiere oder reite. Einige meiner Projekte könnt ihr euch unten anschauen. Außerdem habe ich viel Spaß an der Umsetzung kleinerer Filmprojekte, ein etwas größeres ist aber schon in Arbeit.<br /><br /> Erfahrung habe ich vorallem mit
                                <li>node.js, React/Next.js, HTML5, CSS3, TailwindCSS, MongoDB, Git und JavaScript</li>
                                <li>sprachen (Englisch und Deutsch)</li>
                            <br />
                            Mein Ziel ist es, mich immer weiter zu entwickeln und zu verbessern. Deshalb nehme ich verschieden Aufträge an und versuche diese auch abzuschließen. Einige dieser Aufträge sind unter anderem <a href="https://lukeairtool.net" class="text-blue-500">LukeAirTool</a>, <a href="https://flylat.net" class="text-blue-500">FlyLat</a> und <a href="https://racing.phoenix2022.de/" class="text-blue-500">Phoenix Racing</a>. Meine eigenen Projekte könnt ihr euch <a href="#about-container-projects" class="text-blue-500">hier</a> ansehen.<br /><br />
                            Soviel zu mir, wenn du mich kontaktieren möchtest, kannst du dies gerne über E-Mail oder Discord tun. Ich wünsche dir noch viel Spaß auf der Seite
                            </div>
                        </div>
                        </div>
                        </div>
                       
                    </div>
                </div>
                <div id="about-container-projects" class=" h-max w-full float-none bg-white z-10 relative -mt-9" >
                    <div class="w-3/3 m-auto sm:p-36 p-12 z-10">
                        <div class="sm:text-5xl text-4xl font-extrabold">Meine Projekte</div>
                        <div class="text-light mb-5">Hier kannst du mehr über meine Projekte herausfinden. </div>


                        <div class="h-auto w-full bg-white z-10 bg-no-repeat bg-cover bg-center bg-eve block transform ease-in-out duration-200 hover:scale-105 hover:shadow-big">
                            <div class="h-auto w-full bg-no-repeat bg-cover bg-center block opacity-80 sm:pl-20 sm:pr-20 pr-10 pl-10 pt-20 pb-20 z-10" style={{ background: "radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.8) 100%)" }}>
                                <center>
                                    <div class="sm:text-6xl text-4xl font-extrabold text-white">eveplaner.net</div>
                                    <div class="font-light text-md text-white  mt-2">EvePlaner.net is a next generation event planning tool. EvePlaner offers a variety of catergories for any type of partys. Extremely customizable, easy to use and most importantly: free.</div>
                                    <a href="https://eveplaner.net" class=" underline text-md mt-10 block text-xl" style={{ color: "yellow" }}>Learn more <AiOutlineArrowRight class="inline underline" /></a>
                                </center>
                            </div>
                        </div>

                        <div class="h-auto w-full bg-white z-10 bg-no-repeat bg-cover bg-center bg-airtool block mt-10 transform ease-in-out duration-200 hover:scale-105 hover:shadow-big">
                            <div class="h-auto w-full bg-no-repeat bg-cover bg-center block pl-20 pr-20 pt-20 pb-20 z-10" style={{ background: "radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.8) 100%)" }}>
                                <div class="text-6xl font-extrabold text-white">LukeAirTool</div>
                                <div class="font-light text-md text-white mt-2">LukeAirTool adds real cabin announcements to your flights from easyJet to Etihad, a free passenger simulator and more to enhance your sim experience!</div>
                                <a href="https://lukeairtool.net" class=" underline text-md mt-10 block text-xl" style={{ color: "yellow" }}>Learn more <AiOutlineArrowRight class="inline underline" /></a>

                            </div>
                        </div>

                        <div class="bg-white z-10 bg-no-repeat bg-cover bg-center block mt-10 sm:text-right overflow-hidden relative min-h-1 transform ease-in-out duration-200 hover:scale-105 hover:shadow-big bg-dbleu sm:bg-transparent">
                            <div class="w-full bg-no-repeat bg-cover bg-center block  pl-20 pr-20 pt-20 pb-20 z-10 overflow-hidden relative min-h-1 " style={{ background: "radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.8) 100%)" }}>
                                <div class="sm:text-6xl text-4xl font-extrabold text-white">discord-botlist.eu</div>
                                <div class="font-light text-md text-white  mt-2">discord-botlist.eu offers a wide variety of bots for your personal needs. Find your bot today!</div>
                                <a href="https://discord-botlist.eu" class=" underline text-md mt-10 block text-xl" style={{ color: "yellow" }}>Learn more <AiOutlineArrowRight class="inline underline" /></a>

                            </div>
                        </div>

                        <div class="h-auto w-full bg-white z-10 bg-no-repeat bg-cover bg-center bg-ear-pc block mt-10 transform ease-in-out duration-200  hover:scale-105 hover:shadow-big" style={{}}>
                            <div class="h-auto w-full bg-no-repeat bg-cover bg-center block opacity-80 sm:pl-20 sm:pr-20 pr-10 pl-10 pt-20 pb-20 z-10 " style={{ background: "radial-gradient(ellipse at 50% 50%, rgba(0, 0, 0, 0.3) 20%, rgba(0, 0, 0, 0.8) 100%)"}}>
                                <center>
                                    <div class="sm:text-6xl text-4xl font-extrabold text-white">ear (PC)</div>
                                    <div class="font-light text-md text-white  mt-2">An app to controll your Nothing devices from your computer</div>
                                    <a href="https://github.com/radiance-project/ear-pc" class=" underline text-md mt-10 block text-xl" style={{ color: "yellow" }}>Learn more <AiOutlineArrowRight class="inline underline" /></a>
                                </center>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default Home;
