import React from 'react'
import { MdConstruction } from 'react-icons/md'

class shop extends React.Component{

    render(){
        return(
            <>
            {/* // <div class="flex flex-col items-center justify-center min-h-calc w-full z-50 text-white" style={{backgroundImage:"linear-gradient("+this.state.deg +"deg, #000000, #3b3b3b, #777777, #b9b9b9, #ffffff)"}}></div> */}
            <div class="flex flex-col items-center justify-center min-h-calc w-full z-50 ">
                <h1 class="font-extrabold text-8xl">Under construction <MdConstruction class="inline mt-0 -ml-1"/></h1>
                <p class="text-3xl">We are still working on this page. But dont worry, the wait will be worth it :D</p>
            </div>
            </>
        )
    }
}

export default shop;